<template>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <portal to="header">
      <account-link />
      <h4 class="flex-fill text-center mb-0">Actualités</h4>
      <b-nav-form class="ml-auto" @submit.prevent="add">
        <b-button
          size="sm"
          class="my-2 my-sm-0"
          type="submit"
          variant="outline-secondary"
        >
          <font-awesome-icon icon="user-plus" />
        </b-button>
      </b-nav-form>
    </portal>
    Feed
  </div>
</template>
<script>
export default {
  methods: {
    add() {}
  }
}
</script>
